import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CampaignService {
    rootUrl = `${environment.apiUrl}/api/v1/`;
    public encoder: HttpParameterCodec | any;
    constructor(private http: HttpClient) { }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                    throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    CreateCampaign(data: any) {
        const relativeUrl: string = 'campaigns';
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, data);
    }

    UpdateCampaign(campaignId: string, data: any) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/update";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, data);
    }

    UploadVideo(formData: any) {
        const relativeUrl: string = 'upload/campaign-file-s3';
        const url: string = this.rootUrl + relativeUrl;
        const req = new HttpRequest('POST', url, formData, { reportProgress: true, responseType: 'json', headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) });
        return this.http.request(req);
    }

    AddAttachmentToCampaign(campaignId: string, attachmentId: string, concept_type: number) {
        const data: any = {};
        if (concept_type) {
            data['concept_type'] = concept_type;
        }
        const relativeUrl: string = 'campaigns/' + campaignId + "/add-attachment/" + attachmentId;
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, data);
    }

    RemoveAttachmentFromCampaign(campaignId: string, attachmentId: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/remove-attachment/" + attachmentId;
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, null);
    }

    RemoveAllAttachmentFromCampaign(campaignId: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/remove-all-attachments";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, null);
    }


    AddDiscountFromCampaign(campaignId: any, discountCode: string) {
        const data: any = {};
        if (discountCode) {
            data['code'] = discountCode;
        }
        const relativeUrl: string = 'campaign/' + campaignId + "/attach-discount";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, data);
    }

    RemoveDiscountFromCampaign(campaignId: any) {
        const relativeUrl: string = 'campaign/' + campaignId + "/detach-discount";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, null);
    }

    AddDevicesToCampaign(campaignId: string, devices: number[], dates: string[], slots: string[]) {
        const data: any = {};
        const relativeUrl: string = 'campaigns/' + campaignId + "/add-devices";
        const url: string = this.rootUrl + relativeUrl;

        if (devices && devices.length > 0) {
            data['devices'] = devices;
        }
        if (dates && dates.length > 0) {
            data['dates'] = dates;
        }
        if (slots && slots.length > 0) {
            data['slots'] = slots;
        }
        return this.http.post(url, data);
    }

    AddDevicesToCampaignBulk(campaignId: string, devices: number[], dates: string[], slots: string[]) {
        const data: any = {};
        const relativeUrl: string = 'campaigns/' + campaignId + "/add-devices-bulk";
        const url: string = this.rootUrl + relativeUrl;

        if (devices && devices.length > 0) {
            data['devices'] = devices;
        }
        if (dates && dates.length > 0) {
            data['dates'] = dates;
        }
        if (slots && slots.length > 0) {
            data['slots'] = slots;
        }
        return this.http.post(url, data);
    }

    RemoveDevicesFromCampaign(campaignId: string, devices: number[], dates: string[]) {
        const data: any = {};
        const relativeUrl: string = 'campaigns/' + campaignId + "/remove-devices";
        const url: string = this.rootUrl + relativeUrl;

        if (devices && devices.length > 0) {
            data['devices'] = devices;
        }
        if (dates && dates.length > 0) {
            data['dates'] = dates;
        }
        return this.http.post(url, data);
    }

    RemoveAllDevicesFromCampaign(campaignId: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/remove-all-devices";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, null);
    }

    GetCampaigns(page: number, limit: number, sort: string, filter: any) {
        const relativeUrl: string = 'campaigns?expand=conceptsCount,client';
        const url: string = this.rootUrl + relativeUrl;
        let queryParameters: any = new HttpParams({ encoder: this.encoder });
        if (page !== undefined && page !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>page, 'page');
        }

        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }

        if (sort !== undefined && sort !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>sort, 'sort');
        }

        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.append('filter', filter);
        }

        return this.http.get(url, {
            params: queryParameters
        });
    }

    GetCampaignDetails(campaignId: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + "?expand=attachments,options,client,devices";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);
    }

    GetCampaignDeviceData(campaignId: number, dateFrom: string, dateTo: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + '/device-data?date_from=' + dateFrom + "&date_to=" + dateTo;
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);
    }

    GetCampaignConceptTypes(campaignId: number) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/concept-types";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);
    }

    GetCampaignDevicePreview(campaignId: number, device_type_id: number) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/preview/" + device_type_id;
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);
    }

    GetCampaignChartData(campaignId: string, dateFrom: string, dateTo: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + '/chart-data?date_from=' + dateFrom + "&date_to=" + dateTo;
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);

    }

    GetCampaignPrice(campaignId: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/total-price";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);
    }

    PublishCampaign(campaignId: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/publish";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, null);
    }

    CancelCampaign(campaignId: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/cancel";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, null);
    }

    ArchiveCampaign(campaignId: string) {
        const relativeUrl: string = 'campaigns/' + campaignId + "/archive";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.post(url, null);
    }

    GetDistricts() {
        const relativeUrl: string = 'countries/1/districts';
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);
    }

    GetConcepts() {
        const relativeUrl: string = 'countries/1/home';
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);
    }
    getRunningCampaigns(page: number) {
        const params = {
            page: page,
            'per-page': 10,
        };
        const url: string = `${this.rootUrl}sales/get-running-campaigns`;
        return this.http.get(url, { params: params });
    }
    getCampaignsCount() {
        const url: string = `${this.rootUrl}campaign/campaigns-status-count`;
        return this.http.get(url);
    }
    getCampaigns(page: number, advertiserId: any, filter: any) {
        const params: any = {
            page: page,
            'per-page': 10,
        };
        if (advertiserId) {
            params['advertiser_id'] = advertiserId;
        }
        if (filter.status) {
            params['status'] = filter.status.value.join(',');
        }

        if (filter.keyword) {
            params['keyword'] = filter.keyword;
        }
        if (filter.start_date) {
            params['start_date'] = filter.start_date;
        }

        if (filter.end_date) {
            params['end_date'] = filter.end_date;
        }
        const MethodName: string = 'sales/get-campaigns';
        const url: string = this.rootUrl + MethodName;
        return this.http.get(url, { params: params });
    }

    sendEmailBrand(campaignId: string) {
        const relativeUrl: string = 'campaign/' + campaignId + "/send-email-brand";
        const url: string = this.rootUrl + relativeUrl;
        return this.http.get(url);
    }

    campaignHistory(data: any) {
        const MethodName: string = 'campaign/get-campaignwith-multiple-campign-option-ids';
        const url: string = this.rootUrl + MethodName;
        return this.http.post(url, data);
    }
}
