<div class="terms-container">
    <div class="terms-card">
        <div class="terms-header">
            <h3>Terms and Conditions</h3>
            <p>For HadsUp Affiliate Sales Programme</p>
        </div>
        <div class="terms-content">
            <section class="terms-section">
                <h5>1. Introduction</h5>
                <ul>
                    <li> Welcome to the HadsUp Affiliate Sales Programme. By creating an account and participating in
                        this programme, you agree to be bound by the following terms and conditions ("Terms") between
                        yourself and HadsUp (HADSUP Limited a company incorporated in England and registered under
                        number 12502776) </li>
                </ul>
            </section>
            <section class="terms-section">
                <h5>2. Eligibility</h5>
                <ul>
                    <li> To participate in the HadsUp Affiliate Sales Programme, you must create a sales agent account
                        online or via the HadsUp app and agree to these Terms. </li>
                </ul>
            </section>
            <section class="terms-section">
                <h5>3. Responsibilities of Sales Agents</h5>
                <ul>
                    <li>Sales agents are responsible for adding businesses as advertisers to the HadsUp platform. </li>
                    <li> The businesses added must create an advertiser account and accept the link to the sales agent
                        to track and process payments.</li>
                    <li>Sales agents must ensure that all information provided to HadsUp and advertisers is accurate and
                        up-to-date.</li>
                </ul>
            </section>
            <section class="terms-section">
                <h5>4. Commission and Payment</h5>
                <ul>
                    <li>Sales agents will earn a commission of 30% of the value of each advertising campaign that has
                        sold and completed by advertisers linked to that sales agent.</li>
                    <li> Commissions will be visible in the sales agent’s dashboard once a campaign is booked.</li>
                </ul>
            </section>
            <section class="terms-section">
                <h5>5. Modifications to Commission Structure</h5>
                <ul>
                    <li> HadsUp reserves the right to change the commission structure and amount at any time. Sales
                        agents will be notified of any changes 14 days in advance. </li>
                </ul>
            </section>
            <section class="terms-section">
                <h5>6. Termination</h5>
                <ul>
                    <li> HadsUp may terminate the participation of any sales agent in the programme at its sole
                        discretion. Reasons for termination may include, but are not limited to, violation of these
                        Terms, providing false information, or any actions that could harm HadsUp or its reputation.
                    </li>
                </ul>
            </section>
            <section class="terms-section">
                <h5>7. Limitation of Liability</h5>
                <ul>
                    <li>HadsUp is not liable for any actions, omissions, or claims arising from the activities of the
                        sales agents.</li>
                    <li>Sales agents are responsible for ensuring compliance with all applicable laws and regulations
                        when engaging with advertisers and conducting business activities.</li>
                </ul>
            </section>
            <section class="terms-section">
                <h5>8. Governing Law</h5>
                <ul>
                    <li> This Agreement is governed by the law of England and Wales and any dispute is subject to the
                        exclusive jurisdiction of the Courts of England and Wales. </li>
                </ul>
            </section>
            <section class="terms-section">
                <h5>9. Acceptance of Terms</h5>
                <ul>
                    <li> By creating a sales agent account and participating in the HadsUp Affiliate Sales Programme,
                        you acknowledge that you have read, understood, and agree to be bound by these Terms and
                        Conditions. </li>
                </ul>
            </section>
        </div>
    </div>
</div>